<template>
  <div class="content-box">
    <div class="container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资料管理</el-breadcrumb-item>
        <el-breadcrumb-item>合同管理</el-breadcrumb-item>
      </el-breadcrumb>

      <div
        class="button-gutter"
        v-show="userType=='海装风电'||userType=='超级管理员'"
      >
        <el-button
          style="width: 96px"
          type="primary"
          @click="AddContract"
        >新增合同
        </el-button>
        <div class="tests"></div>
      </div>

      <div class="content_table">
        <el-table
          :data="tableData"
          border
          max-height="600"
          style="width: 100%"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="table_item">
                <el-form>
                  <div class="collspase_item">
                    <div class="collcapse_title">
                      <ul>
                        <li>设备名称</li>
                        <li>设备型号</li>
                        <li v-if="props.row['contractManagement']['controlButton']['delegatedDesign']">协同制造数量</li>
                        <li v-if="props.row['contractManagement']['controlButton']['procurementTask']">采购任务数量</li>
                      </ul>
                    </div>
                    <div class="collcapse_content">
                      <ul>
                        <li>
                          <el-tooltip
                            effect="dark"
                            :content="props.row['contractManagement']['equipmentName']"
                          >
                            <span>{{ props.row['devName'] }}</span>
                          </el-tooltip>
                        </li>
                        <li>{{ props.row.contractManagement.equipmentModel }}</li>
                        <li v-if="props.row['contractManagement']['controlButton']['delegatedDesign']">{{ props.row.contractManagement.equipmentNum }}</li>
                        <li v-if="props.row['contractManagement']['controlButton']['procurementTask']">{{ props.row['buyingTask'] }}</li>
                      </ul>
                      <div class="collcapse_checklist">
                        <span
                          class="cheked_nav"
                          @click="XTmanufacture(props)"
                          v-show="props.row.contractManagement.controlButton.delegatedDesign == true"
                        >协同制造</span>
                        <span
                          v-show="props.row.contractManagement.controlButton.entrustedDesign == true"
                          class="cheked_nav"
                          @click="WTdesgin(props)"
                        >委托设计</span>
                        <span
                          class="cheked_nav"
                          @click="CGtask(props)"
                          v-show="props.row.contractManagement.controlButton.procurementTask == true"
                        >采购任务</span>
                        <span
                          class="cheked_nav"
                          @click="YWsevice(props)"
                          v-show="
                          props.row.contractManagement.controlButton.maintenanceServices == true
                        "
                        >
                          运维服务</span>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="合同名称"
            prop="contractManagement.contractName"
            align="center"
          >
          </el-table-column>

          <el-table-column
            label="供应商"
            prop="userInfo.company"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="负责人"
            prop="userInfo.name"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="合同开始日期"
            prop="contractManagement.timeStart"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="合同结束日期"
            prop="contractManagement.timeEnd"
            align="center"
          ></el-table-column>

          <el-table-column
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <!--            <el-button-->
              <!--                size="mini"-->
              <!--                style="margin-right: 12px"-->
              <!--                @click="Reset(scope.row, scope.$index)"-->
              <!--            >-->
              <!--              编辑-->
              <!--            </el-button>-->
              <el-button
                size="mini"
                type="primary"
                style="margin-right: 12px"
                @click="Download(scope.row, scope.$index)"
                plain
              >
                下载
              </el-button>
              <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @confirm="deleteFile(scope.row, scope.$index)"
            >
              <el-button type="danger" slot="reference" size="mini" plain
                >删除</el-button
              >
            </el-popconfirm> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-paging">
        <el_pagination
          :total="total"
          :num="num"
          @page-limit="page_limit"
        ></el_pagination>
      </div>
      <!-- 新增合同 -->
      <el-dialog
        title="新增合同"
        width="900px"
        :visible.sync="dialogVisible"
        :before-close="Cancel"
        @close="addContractClose"
      >
        <el-form
          ref="contractFrom"
          :model="contractManagementCreateDto"
          label-width="120px"
          label-position="left"
        >
          <el-form-item
            label="供应商"
            prop="contractManagementCreateDto.supplierId"
            required
          >
            <el-cascader
              v-model="contractManagementCreateDto.supplierId"
              :options="supplierOptions"
              :props="formProps"
              @change="supplierChange"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="合同名称"
            required
          >
            <el-input
              v-model="contractManagementCreateDto.contractName"
              placeholder="请输入合同名称"
            />
          </el-form-item>
          <el-form-item
            label="任务名称"
            required
          >
            <el-input
              v-model="contractManagementCreateDto.contractTaskName"
              placeholder="请输入任务名称"
            />
          </el-form-item>
          <el-form-item label="合同开始日期">
            <el-date-picker
              v-model="contractManagementCreateDto.timeStart"
              :picker-options="addpickerOptions"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :style="{ width: '100%' }"
              placeholder="请选择开始时间"
              @change="addchangeData(true)"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="合同截止日期">
            <el-date-picker
              @change="addchangeData(false)"
              :picker-options="addpickerOptions"
              v-model="contractManagementCreateDto.timeEnd"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :style="{ width: '100%' }"
              placeholder="请选择截止时间"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="设备名称"
            required
          >
            <el-cascader
              ref="fileNames"
              v-model="contractManagementCreateDto.productDrawingId"
              :options="bomList"
              :props="drivingProps"
              @change="bomChange"
              clearable
            ></el-cascader>
            <el-input
              style="width:100px;margin-left:5px;text-align:center"
              v-model="place.recordType"
              disabled
            ></el-input>
            <el-input
              style="width:410px;margin-left:5px"
              v-model="place.fileName"
              disabled
            ></el-input>
          </el-form-item>
          <!--        <el-form-item label="设备型号" required>-->
          <!--          <el-input-->
          <!--              v-model="contractManagementCreateDto.equipmentModel"-->
          <!--              placeholder="请输入设备型号"-->
          <!--          />-->
          <!--        </el-form-item>-->
          <el-form-item
            label="设备数量"
            v-if="contractManagementCreateDto.controlButton.delegatedDesign"
            required
          >
            <el-input
              v-model="contractManagementCreateDto.equipmentNum"
              placeholder="请输入设备数量"
            />
          </el-form-item>

          <el-form-item
            label="所属采购任务"
            v-if="contractManagementCreateDto.controlButton.procurementTask"
            required
          >
            <el-input
              v-model="contractManagementCreateDto.buyingTaskName"
              placeholder="请输入所属采购任务"
            />
          </el-form-item>
          <el-form-item
            label="采购任务数量"
            v-if="contractManagementCreateDto.controlButton.procurementTask"
            required
          >
            <el-input
              v-model="contractManagementCreateDto.buyingTaskNum"
              placeholder="请输入采购任务数量"
            />
          </el-form-item>
          <!--        <el-form-item label="计量单位" v-if="contractManagementCreateDto.controlButton.procurementTask">-->
          <!--          <el-input-->
          <!--              v-model="contractManagementCreateDto.measureUnit"-->
          <!--              placeholder="请输入计量单位"-->
          <!--          />-->
          <!--        </el-form-item>-->
          <div>
            <span
              class="contract_item"
              required
            >合同分类:</span>
            <el-checkbox v-model="contractManagementCreateDto.controlButton.delegatedDesign">协同制造
            </el-checkbox>
            <el-checkbox v-model="contractManagementCreateDto.controlButton.entrustedDesign">委托设计
            </el-checkbox>
            <el-checkbox v-model="contractManagementCreateDto.controlButton.procurementTask">采购任务
            </el-checkbox>
            <el-checkbox v-model="
              contractManagementCreateDto.controlButton.maintenanceServices
            ">运维服务
            </el-checkbox>
            <!-- <el-checkbox
            @change="changeFun($event, index)"
            v-for="(item, index) in checkedList"
            :key="item.value"
            :checked="item.checked"
          > -->
          </div>
        </el-form>

        <el-upload
          class="upload-demo"
          :action="actions"
          :headers="tokenData"
          :before-upload="uploads"
          :on-preview="handlePreview"
          :on-progress="newhand"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          ref="upload"
          :loading="loading"
          :on-exceed="handleExceed"
          :on-success="successw"
          accept=".pdf, .doc, .docx,xlsx,xls"
        >
          <el-button type="primary">文件上传</el-button>
          <div
            slot="tip"
            class="el-upload__tip"
          >只能上传.pdf/doc/docx文件</div>
        </el-upload>
        <div class="footer">
          <el-button @click="Cancel">取 消</el-button>
          <el-button
            :disabled='disabled'
            type="primary"
            @click="SendData('upload')"
          >确 定
          </el-button>
        </div>
      </el-dialog>

      <!-- 编辑合同-->
      <el-dialog
        title="编辑"
        :visible.sync="resetdialogVisible"
        :before-close="Cancel"
      >
        <el-form
          ref="resetContractFrom"
          :model="resetContractFrom"
          label-width="120px"
          label-position="left"
        >
          <el-form-item
            label="供应商"
            required
          >
            <el-cascader
              v-model="resetContractFrom.supplierId"
              :options="supplierOptions"
              :props="formProps"
              @change="supplierChange"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item
            label="合同名称"
            required
          >
            <el-input
              v-model="resetContractFrom.contractName"
              placeholder="请输入合同名称"
            />
          </el-form-item>
          <el-form-item
            label="任务名称"
            required
          >
            <el-input
              v-model="resetContractFrom.contractTaskName"
              placeholder="请输入任务名称"
            />
          </el-form-item>
          <el-form-item
            label="合同开始日期"
            required
          >
            <el-date-picker
              v-model="resetContractFrom.timeStart"
              :picker-options="pickerOptions"
              @change="changeData(true)"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :style="{ width: '100%' }"
              placeholder="请选择开始时间"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="合同截止日期"
            required
          >
            <el-date-picker
              v-model="resetContractFrom.timeEnd"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :style="{ width: '100%' }"
              placeholder="请选择截止时间"
              @change="changeData(false)"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="设备名称"
            required
          >
            <el-cascader
              :options="bomList"
              :props="drivingProps"
              @change="bomChange"
              clearable
            ></el-cascader>
          </el-form-item>
          <!--        <el-form-item label="设备型号" required>-->
          <!--          <el-input-->
          <!--              v-model="contractManagementCreateDto.equipmentModel"-->
          <!--              placeholder="请输入设备型号"-->
          <!--          />-->
          <!--        </el-form-item>-->
          <el-form-item
            label="设备数量"
            v-if="resetContractFrom.controlButton.delegatedDesign"
            required
          >
            <el-input
              v-model="resetContractFrom.equipmentNum"
              placeholder="请输入设备数量"
            />
          </el-form-item>
          <el-form-item
            label="所属采购任务"
            v-if="resetContractFrom.controlButton.procurementTask"
            required
          >
            <el-input
              v-model="resetContractFrom.buyingTaskName"
              placeholder="请输入所属采购任务"
            />
          </el-form-item>
          <el-form-item
            label="采购任务数量"
            v-if="resetContractFrom.controlButton.procurementTask"
            required
          >
            <el-input
              v-model="resetContractFrom.buyingTaskNum"
              placeholder="请输入采购任务数量"
            />
          </el-form-item>
          <!--        <el-form-item label="计量单位" v-if="resetContractFrom.controlButton.procurementTask" required>-->
          <!--          <el-input-->
          <!--              v-model="resetContractFrom.measureUnit"-->
          <!--              placeholder="请输入计量单位"-->
          <!--          />-->
          <!--        </el-form-item>-->

          <div class="restChecked_item">
            <span class="contract_item">合同分类:</span>
            <el-checkbox v-model="resetContractFrom.controlButton.delegatedDesign">协同设计
            </el-checkbox>
            <el-checkbox v-model="resetContractFrom.controlButton.entrustedDesign">委托设计
            </el-checkbox>
            <el-checkbox v-model="resetContractFrom.controlButton.procurementTask">采购任务
            </el-checkbox>
            <el-checkbox v-model="resetContractFrom.controlButton.maintenanceServices">运维服务
            </el-checkbox>
            <el-upload
              class="upload-demo"
              :action="actions"
              :headers="tokenData"
              :before-upload="uploads"
              :on-preview="resthandlePreview"
              :on-progress="newhand"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="1"
              ref="upload"
              :loading="loading"
              :on-exceed="resthandleExceed"
              :on-success="resetsuccessw"
              accept=".pdf, .doc, .docx,xlsx,xls"
            >
              <el-button type="primary">文件上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                只能上传.pdf/doc/docx文件
              </div>
            </el-upload>
          </div>
        </el-form>
        <div class="footer">
          <el-button @click="Cancel">取 消</el-button>
          <el-button
            type="primary"
            @click="ResetData(resetContractFrom)"
            :disabled="restdisabled"
          >确 定
          </el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { documentRemove, contractlist, addContractList, resetContractList, deleteContractList } from '@/api/document';
import { supplierList } from '@/api/document';
import { getUploadType, contractDownload } from '@/api/file';
import { bomTypeList, getBomType, bomTableList } from '@/api/bom';
import { fileList } from '@/api/product';

export default {
    activeNames: ['1'],
    handleChange(val) {
        console.log(val);
    },
    name: 'Contract',
    components: {},
    data() {
        return {
            place: '',
            typeList: [],
            userType: localStorage.getItem('userType'),
            active: false,
            disabled: true,
            restdisabled: true,
            loading: false,
            dialogVisible: false,
            resetdialogVisible: false,
            tableData: [],
            bomList: [],
            supplierOptions: [],
            formProps: {
                label: 'name',
                value: 'id',
                children: 'value'
            },
            drivingProps: {
                label: 'materialDescribe',
                value: 'id',
                children: 'value'
            },
            pickerOptions: {
                disableDate: (time) => {
                    return time.getTime() > Date.now();
                }
            },
            addpickerOptions: {
                disableDate: (time) => {
                    return time.getTime() > Date.now();
                }
            },

            userName: localStorage.getItem('name'),
            resetContractFrom: {
                timeEnd: '',
                timeStart: '',
                controlButton: {}
            },
            upload_file: '',
            fileList: [],
            time1: '',
            actions: process.env.VUE_APP_BASE_API + `/file/upload`,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            checkedList: [
                { id: 0, value: '委托设计', checked: false },
                { id: 1, value: '协同制造', checked: false },
                { id: 2, value: '采购任务', checked: false },
                { id: 3, value: '运维服务', checked: false }
            ],
            contractManagementCreateDto: {
                contractFileId: '',
                //  driveName: null,
                controlButton: {
                    delegatedDesign: false,
                    entrustedDesign: false,
                    procurementTask: false,
                    maintenanceServices: false
                }
            }
        };
    },
    mounted() {
        this.getFileList();
        this.getSupplier();
        this.fetchBomType();
        console.log(this.userType);
    },
    computed: {
        tokenData() {
            return {
                Authorization: window.localStorage.getItem('token')
            };
        }
    },
    watch: {
        'contractManagementCreateDto.productDrawingId'(newVal, oldVal) {
            console.log(newVal);
            if (newVal.length===0) {
                this.place = '';
            }
        }
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //获取供应商
        async getSupplier() {
            const res = await supplierList();
            const data = res['data'];
            data.forEach((item) => {
                item['name'] = item['company'];
                item['id'] = item['key'];
            });
            this.supplierOptions = data;
        },
        //判断当前时间

        changeData(flag) {
            if (flag) {
                if (this.resetContractFrom.timeEnd) {
                    if (this.resetContractFrom.timeStart > this.resetContractFrom.timeEnd) {
                        this.resetContractFrom.timeStart = null;
                        this.$message.error('开始时间不能大于结束时间！');
                    }
                }
            } else {
                if (this.resetContractFrom.timeStart) {
                    if (this.resetContractFrom.timeStart > this.resetContractFrom.timeEnd) {
                        this.resetContractFrom.timeEnd = null;
                        this.$message.error('结束时间不能小于开始时间！');
                    }
                }
            }
        },
        addchangeData(flag) {
            if (flag) {
                if (this.contractManagementCreateDto.timeEnd) {
                    if (this.contractManagementCreateDto.timeStart > this.contractManagementCreateDto.timeEnd) {
                        this.contractManagementCreateDto.timeStart = null;
                        this.$message.error('开始时间不能大于结束时间！');
                    }
                }
            } else {
                if (this.contractManagementCreateDto.timeStart) {
                    if (this.contractManagementCreateDto.timeStart > this.contractManagementCreateDto.timeEnd) {
                        this.contractManagementCreateDto.timeEnd = null;
                        this.$message.error('结束时间不能小于开始时间！');
                    }
                }
            }
        },

        //合同表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await contractlist({
                limit,
                page
            });
            if (res.code == 200) {
                const data = res['data'];
                data.forEach((item) => {
                    const name = item['contractManagement']['equipmentName'];
                    name.length >= 15
                        ? (item['devName'] = `${name.substring(0, name.length - (name.length - 15))}...`)
                        : (item['devName'] = name);
                });
                this.tableData = res.data;
                this.total = res['total'];
                this.num = parseInt(this.total / limit);
            }
        },
        // 删除合同
        async deleteFile(row, $index) {
            let id = row.id;
            const res = await deleteContractList({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        // 新增合同弹框
        AddContract() {
            this.dialogVisible = true;
        },

        //合同所属列表
        changeFun(e, index) {
            this.checkedList[index].checked = e;
            console.log(e, index);
            console.log(this.checkedList);
        },
        // 确定新增
        async SendData(upload) {
            console.log(this.contractManagementCreateDto);
            let res = await addContractList(this.contractManagementCreateDto);
            if (res.code == 200) {
                this.$message.success('新增成功');
                this.getFileList();
                this.dialogVisible = false;
                this.$refs[upload].clearFiles();
                this.contractManagementCreateDto = {
                    controlButton: {
                        delegatedDesign: false,
                        entrustedDesign: false,
                        procurementTask: false,
                        maintenanceServices: false
                    }
                };
                this.disabled = true;
            } else {
                this.$message.error(res.message);
            }
        },

        //  编辑合同
        Reset(row, $index) {
            this.resetdialogVisible = true;
            console.log(row);
            let data = {
                id: row.id,
                contractFileId: row.contractFileId,
                contractName: row.contractName,
                controlButton: row.controlButton,
                equipmentName: row.equipmentName,
                equipmentNum: row.equipmentNum,
                manageId: row.manageId,
                supplierId: row.supplierId,
                other: row.other,
                timeStart: row.timeCreated,
                timeEnd: row.timeEnd,
                equipmentModel: row.equipmentModel
            };
            this.supplierOptions.forEach((item) => {
                const companyId = item['id'];
                item['value'].forEach((it) => {
                    if (it['id'] == data['supplierId']) {
                        data['test'] = [];
                        data['test'].push(companyId);
                        data['test'].push(it['id']);
                    }
                });
            });
            this.bomList.forEach((item) => {
                const bomType = item['materialDescribe'];
                item['value'].forEach((it) => {
                    if (it['id'] == data['materialDescribe']) {
                        data['driveName'] = [];
                        data['driveName'].push(bomType);
                        data['driveName'].push(it['id']);
                    }
                });
            });
            this.resetContractFrom = JSON.parse(JSON.stringify(data));
            // console.log(this.resetContractFrom.controlButton);
        },

        // 确定编辑
        async ResetData(resetContractFrom) {
            console.log(resetContractFrom);

            let res = await resetContractList(resetContractFrom);

            if (res.code == 200) {
                this.$message.success('编辑成功');
                this.resetdialogVisible = false;
                this.resetContractFrom = {
                    controlButton: {
                        delegatedDesign: false,
                        entrustedDesign: false,
                        procurementTask: false,
                        maintenanceServices: false
                    }
                };
            } else {
                this.$message.error('编辑失败');
            }
            await this.getFileList();
        },

        Cancel() {
            this.dialogVisible = false;
            this.resetdialogVisible = false;
            this.disabled = true;
            this.restdisabled = true;
            this.resetContractFrom = {
                controlButton: {
                    delegatedDesign: false,
                    entrustedDesign: false,
                    procurementTask: false,
                    maintenanceServices: false
                }
            };
            this.contractManagementCreateDto = {
                controlButton: {
                    delegatedDesign: false,
                    entrustedDesign: false,
                    procurementTask: false,
                    maintenanceServices: false
                }
            };
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        resthandlePreview(file) {
            console.log(file);
        },
        resthandleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },

        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        resetsuccessw(response, file, fileList) {
            this.loading = true;
            console.log(response);
            if (response.code == 200) {
                this.restdisabled = false;
                console.log(this.restdisabled);
                this.loading = false;
                this.resetContractFrom.contractFileId = response.data;
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
                console.log(response.data);
            } else {
                this.$message.error('上传失败');
            }
        },

        successw(response, file, fileList) {
            this.loading = true;
            console.log(response);
            if (response.code == 200) {
                this.disabled = false;
                this.loading = false;
                this.contractManagementCreateDto.contractFileId = response.data;
                this.$message({
                    message: '上传成功',
                    type: 'success'
                });
                console.log(response.data);
            } else {
                this.$message.error('上传失败');
            }
        },
        uploads(file, fileList) {
            this.fileList = fileList;
            const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
            const whiteList = ['pdf', 'doc', 'docx', 'doc'];
            const isSuffix = whiteList.indexOf(fileSuffix.toLowerCase()) === -1;
            const isLt10M = file.size / 1024 / 1024 > 10;
            console.log('this.fileList:', this.fileList);
            if (isSuffix) {
                this.$message.error('上传文件只能是 pdf、doc、docx格式');
                const currIdx = this.fileList.indexOf(file);
                this.fileList.splice(currIdx, 1);
                return;
            }
            if (isLt10M) {
                this.$message.error('上传文件大小不能超过 10MB');
                const currIdx = this.fileList.indexOf(file);
                this.fileList.splice(currIdx, 1);
                return;
            }
        },
        newhand(event, file, fileList) {
            console.log(event);
        },
        WTdesgin(props) {
            let id = props.row.contractManagement.id;

            this.$router.push({
                path: '/CollaborativeManagement/entrustedDesign',
                query: { id: id }
            });
        },
        XTmanufacture(props) {
            let id = props.row.contractManagement.id;
            this.$router.push({
                path: '/CollaborativeManagement/productionScheduleManagement',
                query: { id: id }
            });
        },
        CGtask(props) {
            let id = props.row.contractManagement.id;
            this.$router.push({
                path: '/CollaborativeManagement/procurementTask',
                query: { id: id }
            });
        },
        YWsevice(props) {
            let id = props.row.contractManagement.id;
            this.$router.push({
                path: '/CollaborativeManagement/operationMaintenanceScheme',
                query: { id: id }
            });
        },
        // OpenNav(props) {
        //   this.active = true;
        //   console.log(props.row.id);
        // },
        async Download(row, scope) {
            console.log(row);
            let id = row.contractManagement.contractFileId;
            const res = await getUploadType({ id });
            if (res.code == 200) {
                console.log(res.data);
                let id = row.contractManagement.contractFileId;
                let dwRes = await contractDownload({ id: id });
                let url = window.URL.createObjectURL(new Blob([dwRes]));
                // const url = new Blob([res])
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.download = row.contractManagement.contractName + res.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            // let url = window.URL.createObjectURL(new Blob([res]));
            // // const url = new Blob([res])
            // const link = document.createElement("a");
            // link.style.display = "none";
            // link.href = url;
            // link.download = row.fileName + row.fileType;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        },
        supplierChange(res) {
            // this.contractManagementCreateDto['supplierId'] = res[res.length - 1]
            this.contractManagementCreateDto.supplierId = res[1];
        },
        addContractClose() {
            this.place = '';
        },
        async fetchBomType() {
            const response = await getBomType();
            const data = response['data'];
            const result = [];
            let index = 2001;
            data.forEach((item) => {
                if (item != '维修BOM') {
                    const jsonb = {};
                    jsonb['materialDescribe'] = item;
                    jsonb['id'] = index++;
                    this.fetchBomList(item).then((typeList) => {
                        jsonb['value'] = typeList;
                    });
                    result.push(jsonb);
                }
            });
            this.bomList = result;
            console.log(this.bomList);
        },
        async fetchBomList(type) {
            const response = await bomTypeList({
                bomType: type
            });
            return response['data'];
        },
        async bomChange(res) {
            const data = await this.requestBomTableList({ id: res[1] });
            let productDrawingId = data[0]['productDrawingId'];
            const result = await this.requestFileList({ id: productDrawingId });
            console.log(result);
            this.place = result[0];
            //  console.log(res[res.length - 1])
            // this.contractManagementCreateDto['driveName'] = res[res.length - 1]
            this.contractManagementCreateDto.bomId = res[1];
        },
        async requestBomTableList(data) {
            const response = await bomTableList(data);
            return response['data'];
        },
        async requestFileList(data) {
            const response = await fileList(data);
            return response['data'];
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}

::v-deep .collapse-title {
    flex: 1 0 90%;
    order: 1;

    .el-collapse-item__header {
        flex: 1 0 auto;
        order: -1;
    }
}

.Foldbar {
    width: 200px;
    height: 200px;
    background: red;
}

.tests {
    margin: -40px 0px 0px 120px;
}

.table_weituo {
    color: #5d99ff;
}

.table_item {
    width: 100%;
    height: 100%;
}

::v-deep .table_item .el-form {
    height: 100%;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
}

.table-paging {
    right: 60px;
    position: absolute;
}

.content_table {
    margin-top: 80px;
}

.collspase_item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.collcapse_title {
    margin-left: 325px;

    ul {
        width: 400px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        list-style: none;
    }

    width: 100%;
    height: 50%;

    li {
        width: 90px;
        text-align: center;
    }
}

.collcapse_content {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    ul {
        width: 400px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        list-style: none;
    }

    li {
        width: 90px;
        text-align: center;
    }
}

.collcapse_checklist {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 500px;
    color: rgb(0, 140, 255);
}

::v-deep .el-dialog__header {
    text-align: center;
}

.footer {
    width: 300px;
    margin-top: 50px;
    margin: 0 auto;
}

.contract_item {
    margin-left: 10px;
    margin-right: 40px;
}

.restChecked_item {
    margin-bottom: 30px;
}
</style>

