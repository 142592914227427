import request from '@/utils/request'

export function bomTypeList(params) {
    return request({
        url: '/bomTable/bomType/list',
        method: 'get',
        params
    })
}

export function getBomType() {
    return request({
        url: '/bomTable/getBomType',
        method: 'get'
    })
}
export function bomTableList(params) {
    return request({
        url: '/bomTable/list',
        method: 'get',
        params
    })
}